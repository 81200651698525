import { MEASUREMENT_UNIT } from '@models/common.model'
import { AES, enc } from 'crypto-js'
import { sha3_512 } from 'js-sha3'
import { v4 as uuidv4 } from 'uuid'
import { LodashUtils } from './lodash'

export const LOCAL_STORAGE_KEY = {
  AUTHENTICATION: 'authentication',
  SPCP_AUTH_HEADER: 'code',
}

export class Validations {
  static RegexSpecial = /^[a-zA-Z0-9\s]*$/
  static isSpecialChar(input: string): boolean {
    return !this.RegexSpecial.test(String(input).toLowerCase())
  }

  static RegexEmail = /^[\w-.]+@([\w-]+\.)+[a-zA-Z-]+$/
  static isValidEmail(input: string): boolean {
    return this.RegexEmail.test(String(input).toLowerCase())
  }

  static RegexPhoneNumber = /^(?:\+65\s?)?[3689]\d{7}$/
  static isValidPhoneNumber(input: string): boolean {
    return this.RegexPhoneNumber.test(String(input))
  }

  // eslint-disable-next-line no-useless-escape
  static RegexAlphanumeric = /^[a-zA-Z0-9,.\s\/]+$/
  static isAlphanumeric(input: string) {
    return this.RegexAlphanumeric.test(input)
  }

  // eslint-disable-next-line no-useless-escape
  static RegexSpecialCharacters = /^[a-zA-Z0-9\s\:\.\,\/\%\-\_\(\)]+$/g
  static isSpecialCharacter(input: string): boolean {
    return !this.RegexSpecialCharacters.test(String(input).toLowerCase())
  }
}

export class Helpers {
  static generateID(): string {
    return uuidv4()
  }

  static getCurrentTime = () => new Date().getTime()

  static PersistExpired = 'persist:expired'
  static PersistData = 'persist:root'
  static PersistLastIdle = 'persist:expired-idle'
  static setLastUpdated = (isNeeded = true): void => {
    if (isNeeded) {
      localStorage.setItem(this.PersistExpired, `${this.getCurrentTime()}`)
    }
  }

  static setLastIdle = (): void => {
    localStorage.setItem(this.PersistLastIdle, `${this.getCurrentTime()}`)
  }

  static resetLastIdle = (): void => {
    localStorage.setItem(this.PersistLastIdle, '0')
  }

  static getLastIdle = (): number => {
    return this.toNumber(localStorage.getItem(this.PersistLastIdle))
  }

  static clearCachedData = (): void => {
    localStorage.removeItem(this.PersistData)
    localStorage.removeItem(this.PersistLastIdle)
    localStorage.removeItem(this.PersistExpired)
    sessionStorage.clear()
  }

  static checkShouldClearCached = (): boolean => {
    const current = this.getCurrentTime()
    const timeout = this.toNumber(process.env.REACT_APP_TIMEOUT) * 1000 * 60
    const remainingTimeout =
      this.toNumber(process.env.REACT_APP_TIMEOUT_REMAINING) * 1000 * 60
    const totalTimeout = timeout + remainingTimeout

    const lastUpdated = this.toNumber(localStorage.getItem(this.PersistExpired))

    return current - lastUpdated >= totalTimeout
  }

  static async hash256(message?: string) {
    if (LodashUtils.isEmpty(message)) {
      return ''
    }
    return await import('crypto-js/sha256').then((resp) =>
      sha3_512(resp.default(message).toString()),
    )
  }

  static toLitres(cubicValue: number) {
    return cubicValue * 1000
  }

  static toCubicMeters(littersValue: number) {
    return littersValue / 1000
  }

  static getMeasurementUnitLabel(unit: MEASUREMENT_UNIT): string {
    switch (unit) {
      case MEASUREMENT_UNIT.LITRES:
        return 'Litres'
      case MEASUREMENT_UNIT.CUBIC:
      default:
        return 'Cu M'
    }
  }

  static getAbbr(num: number) {
    const length = Math.floor(num).toString().length
    const arr = [
      '',
      '',
      '',
      '',
      'k',
      'k',
      'k',
      'mil',
      'mil',
      'mil',
      'bil',
      'bil',
      'bil',
      'trill',
    ]
    const idx = Math.min(length, arr.length - 1)
    return arr[idx]
  }

  static getDividedNumber(num: number) {
    const length = Math.floor(num).toString().length
    const arr = [1, 1, 1, 1, 1e3, 1e3, 1e3, 1e6, 1e6, 1e6, 1e9, 1e9, 1e9, 1e12]
    const idx = Math.min(length, arr.length - 1)
    return arr[idx]
  }

  static roundNumber(num: number, decPlaces, padding = false) {
    const round = Math.pow(10, decPlaces)
    const result = Math.round(num * round) / round
    return padding ? result.toFixed(decPlaces) : result
  }

  static abbrNum(number: number, decPlaces = 1, padding = false): string {
    const abb = this.getAbbr(number)
    const divided = this.getDividedNumber(number)
    return (
      this.roundNumber(Math.abs(number / divided), decPlaces, padding) + abb
    )
  }

  static abbrNumNotRound(number: number, decPlaces = 1): string {
    const abb = this.getAbbr(number)
    const divided = this.getDividedNumber(number)
    return Math.abs(number / divided).toFixed(decPlaces) + abb
  }

  static toNumber = (input: any, fallback = 0) => {
    const number = LodashUtils.toNumber(input)
    return LodashUtils.isNaN(number) ? fallback : number
  }

  static formatNumber(
    number: number | string,
    dec = 2,
    padding = false,
  ): string {
    const num = Number(number)
    if (!num) {
      return '0'
    }
    return this.abbrNum(num, dec, padding)
  }

  static formatSingpassPhoneNumber = (origin: string): string => {
    const str = LodashUtils.toString(origin)
    if (LodashUtils.isEmpty(str)) {
      return ''
    }
    return origin.replace(' ', '').split(' ')[1]
  }

  static base64ToBlob = (
    base64str: string,
    contentType: string,
    sliceSize = 512,
  ): Blob => {
    const byteCharacters = window.atob(base64str)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
  }

  static getURLSearchParams(url: string): URLSearchParams {
    return new URLSearchParams(url)
  }
  // convert base64
  static encodeData(str: string) {
    return window.btoa(str)
  }

  static checkIsMergedAccount = (account: any) => {
    return (
      account?.mergedWaterAccountId !== null && account?.accountNumber === null
    )
  }

  static ordinalSuffixOf = (number: number) => {
    const j = number % 10
    const k = number % 100
    if (j === 1 && k !== 11) {
      return 'st'
    }
    if (j === 2 && k !== 12) {
      return 'nd'
    }
    if (j === 3 && k !== 13) {
      return 'rd'
    }
    return 'th'
  }

  static isDev = process.env.NODE_ENV === 'development'
}

const SECRET_KEY = '9D53B6D1994B2A2EB9C6F7EC35AB96F9'

export const encrypt = (obj: any) => {
  return AES.encrypt(JSON.stringify(obj), SECRET_KEY).toString()
}

export const decrypt = (hash: any, defaultValue?: any) => {
  return hash
    ? JSON.parse(AES.decrypt(hash, SECRET_KEY).toString(enc.Utf8))
    : defaultValue
}
